export default {
  pages: {
    applicationsList: {
      filtersBlock: {
        labels: {
          deliveryExists: 'Yetkazib berish mavjudligi',
        },
        placeholders: {
          deliveryExists: 'Hammasi',
        },
        hasDelivery: 'Yetkazib berish mavjud',
        hasNoDelivery: 'Yetkazib berish yo‘q',
      },
    },
  },
};
