// todo refactor module name

const moduleName = 'module_azo';

import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import { useIsNewOnboarding } from '@/modules/main/common/stores';

export default [
  {
    path: '/nasiya/clients',
    name: ROUTE_NAMES.clientsList,
    component: () => import('../views/NasiyaClientsIndexPage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.clientsListToFsd,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Клиенты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/clients/to-fsd',
    name: ROUTE_NAMES.clientsListToFsd,
    component: async () => {
      const { ClientsListPage } = await import('@/to-fsd/pages/clients-list');

      return ClientsListPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Клиенты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },

  {
    path: '/nasiya/clients/:id',
    name: ROUTE_NAMES.clientsShow,
    component: () => import('../views/NasiyaClientsShowPage'),
    redirect: to => {
      return {
        name: ROUTE_NAMES.clientsShowToFsd,
        params: {
          clientId: to.params.id,
        },
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/clients/:clientId/to-fsd',
    name: ROUTE_NAMES.clientsShowToFsd,
    component: async () => {
      const { ClientsShowPage } = await import('@/to-fsd/pages/clients-show');

      return ClientsShowPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
        ({ next, to }) => {
          const isNewOnboarding = useIsNewOnboarding();

          if (!isNewOnboarding.value) {
            return {
              name: ROUTE_NAMES.clientsShowToVueQuery,
              params: {
                id: to.params.clientId,
              },
            };
          }

          return next();
        },
      ],
    },
  },
  {
    path: '/nasiya/clients/:clientId/confirm-agreement',
    name: ROUTE_NAMES.clientConfirmAgreement,
    component: async () => {
      const { ClientConfirmAgreementPage } = await import('@/to-fsd/pages/client-confirm-agreement');

      return ClientConfirmAgreementPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/clients/:id/to-vue-query',
    name: ROUTE_NAMES.clientsShowToVueQuery,
    component: () => import('../to-vue-query/views/NasiyaClientsShowPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
        ({ next, to }) => {
          const isNewOnboarding = useIsNewOnboarding();

          if (isNewOnboarding.value) {
            return {
              name: ROUTE_NAMES.clientsShowToFsd,
              params: {
                clientId: to.params.id,
              },
            };
          }

          return next();
        },
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/clients/:clientId/applications/new',
    name: ROUTE_NAMES.createApplication,
    component: async () => {
      const { CreateApplicationPage } = await import('@/to-fsd/pages/create-application');

      return CreateApplicationPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/clients/by-phone/:clientPhone/update-passport',
    name: ROUTE_NAMES.clientsUpdateClientPassport,
    component: () => import('../views/NasiyaClientsUpdatePassportPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Обновить паспортные данные'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/clients/create',
    name: ROUTE_NAMES.clientsCreate,
    component: () => import('../views/NasiyaClientsCreatePage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.clientsNew,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Новый клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/clients/new/old-onboarding',
    name: ROUTE_NAMES.clientsNewOldOnBoarding,
    component: async () => {
      const { AddNewClientPage } = await import('@/to-fsd/pages/old-onboarding-add-new-client');

      return AddNewClientPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Новый клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/clients/new',
    name: ROUTE_NAMES.clientsNew,
    component: async () => {
      const { AddNewClientPage } = await import('@/to-fsd/pages/add-new-client');

      return AddNewClientPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Новый клиент'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
];
