export default {
  widgets: {
    applicationsListTable: {
      filtersBlock: {
        labels: {
          deliveryExists: 'Наличие доставки',
        },
        placeholders: {
          deliveryExists: 'Все',
        },
        hasDelivery: 'Есть доставка',
        hasNoDelivery: 'Нет доставки',
      },
    },
  },
};
