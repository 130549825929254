export default {
  widgets: {
    applicationsListTable: {
      filtersBlock: {
        labels: {
          deliveryExists: 'Yetkazib berish mavjudligi',
        },
        placeholders: {
          deliveryExists: 'Hammasi',
        },
        hasDelivery: 'Yetkazib berish mavjud',
        hasNoDelivery: 'Yetkazib berish yo‘q',
      },
    },
  },
};
