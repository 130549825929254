import { useMainStore } from '@/modules/main/common/stores';
import { useUserStore } from '@/modules/main/user/stores';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';

const requireAccessToModule = moduleName => {
  return async ({ next }) => {
    const mainStore = useMainStore();
    const userStore = useUserStore();

    const userPermissions = userStore.me.permissions;
    const userPermissionNames = userPermissions.map(permission => permission.name);

    const accessMap = {
      module_azo: mainStore.app.merchant.modules.module_azo && userPermissionNames.includes('merchant_alifnasiya'),
      module_acquiring:
        userPermissionNames.includes('merchant_acquiring') &&
        (mainStore.app.merchant.modules.module_acquiring || mainStore.app.merchant.modules.module_bml),
      module_alifshop:
        mainStore.app.merchant.modules.module_alifshop && userPermissionNames.includes('merchant_alifshop'),
      module_admin:
        ((mainStore.app.merchant.modules.module_azo && userPermissionNames.includes('merchant_alifnasiya')) ||
          (mainStore.app.merchant.modules.module_acquiring && userPermissionNames.includes('merchant_acquiring')) ||
          (mainStore.app.merchant.modules.module_alifshop && userPermissionNames.includes('merchant_alifshop'))) &&
        userPermissionNames.includes('merchant_admin'),
    };

    const hasAccess = accessMap[moduleName];

    if (!hasAccess) {
      return { name: ROUTE_NAMES.moduleAccessDenied };
    }

    return next();
  };
};

export default requireAccessToModule;
