import { modulesList, modulesName } from '../../../main/common/constants/modules';
import { useMainStore } from '@/modules/main/common/stores';

export const getters = {
  userFullName: state => {
    return state?.me?.name;
  },
  userPhone: state => {
    return state?.me?.phone;
  },
  getDefaultModuleRoute: state => {
    const mainStore = useMainStore();
    const permissionNames = state.me.permissions.map(p => p.name);

    const result = [];
    const modules = {
      module_azo: mainStore.app.merchant.modules.module_azo && permissionNames.includes('merchant_alifnasiya'),
      module_acquiring:
        permissionNames.includes('merchant_acquiring') &&
        (mainStore.app.merchant.modules.module_acquiring || mainStore.app.merchant.modules.module_bml),
      module_alifshop: mainStore.app.merchant.modules.module_alifshop && permissionNames.includes('merchant_alifshop'),
      module_admin: permissionNames.includes('merchant_admin'),
    };
    const sortedModulesNames = ['module_azo', 'module_alifshop', 'module_acquiring', 'module_admin'];
    sortedModulesNames.forEach(el => {
      if (modules[el] && modulesList[el]) {
        const name = el;
        const route = modulesList[el].homeRoute;
        result.push({ route, name });
      }
    });
    return result?.find(el => el.name === modulesName.NASIYA)?.route || result[0]?.route;
  },
};
