export default {
  features: {
    addOrFindNewClient: {
      checkClientStatus: {
        title: 'Mijoz qoʻshish/qidirish',
        fields: {
          passport: {
            placeholder: 'Pasport seriyasi va raqami',
          },
          phone: {},
        },
        submit: 'Mijoz qoʻshish/qidirish',
      },
      clientExistsModal: {
        title: 'Shunday maʼlumotlarga ega mijoz mavjud',
        subTitle:
          'Boʻlib-boʻlib toʻlash uchun roʻyxatdan oʻtish mijozning mobil telefonidagi Alif Business ilovasi orqali amalga oshiriladi',
        steps: [
          'QR-kodni skanerlash orqali ilovani yuklab oling',
          'Mijoz maʼlumotlarini kiriting',
          'Mijozni MyID orqali roʻyxatdan oʻtkazing',
        ],
        footer: 'Soxta pasport maʼlumotlariga ega mijozlarni yaratmang!',
        ok: 'Tushunarli',
      },
      clientConfirmAgreement: {
        title: 'Maʼlumotlarni qayta ishlashga rozilik',
        smsWasSentToClient: 'SMS-kod mijoz raqamiga yuborildi ',
        fields: {
          otp: {
            label: 'SMS-kod',
          },
        },
        confirm: 'Tasdiqlash',
      },
      createClient: {
        ensureDataAreCorrectAlert: {
          title: 'Maʼlumotlarni qayta tekshiring!',
          body: 'Mijoz yaratilgandan soʻng, bu maʼlumotlarni oʻzgartirish mumkin boʻlmaydi',
        },
        passport: {
          title: 'Mijozning pasporti',
          uploadPhotoMessage: 'Mijoz pasportining fotosuratini yuklang',
          fields: {
            passportPhoto: {
              label: 'Pasport fotosurati',
            },
          },
          continue: 'Davom etish',
        },
        idPassport: {
          title: 'ID karta',
          uploadPhotoMessage: 'Mijoz ID kartasining fotosuratini yuklang',
          fields: {
            passportIdPhotoFront: {
              label: 'ID kartaning old tomoni',
            },
            passportIdPhotoBack: {
              label: 'ID kartaning orqa tomoni',
            },
          },
          submit: 'Davom etish',
        },
        passportData: {
          gender: {
            M: 'Erkak',
            F: 'Ayol',
          },
          fields: {
            passportId: {
              label: 'Seriya va raqam',
            },
            pinfl: {
              label: 'PINFL',
            },
            region: {
              label: 'Hudud',
            },
            address: {
              label: 'Roʻyxatga olish manzili',
            },
            nationalityName: {
              label: 'Fuqarolik',
            },
            birthplace: {
              label: 'Tugʻilgan joyi',
            },
            issueDate: {
              label: 'Berilgan sana',
              placeholder: 'yil-oy-kun',
            },
            expiresDate: {
              label: 'Amal qilish muddati',
              placeholder: 'yil-oy-kun',
            },
            issuedBy: {
              label: 'Kim tomonidan berilgan',
              placeholder: 'Pasport kim tomonidan berilgan',
            },
            name: {
              label: 'Ism',
            },
            surname: {
              label: 'Familiya',
            },
            patronymic: {
              label: 'Otasining ismi',
            },
            gender: {
              label: 'Jinsi',
            },
            birthDate: {
              label: 'Tugʻilgan sana',
            },
            cityName: {
              label: 'Tugʻilgan joyi',
            },
          },
          submit: 'Saqlash',
          cancel: 'Bekor qilish',
          fetchOcr: 'Fotosuratdan qayta o‘qish',
        },
      },
      createClientOtpStep: {
        title: 'Tasdiqlash kodi',
        smsWasSentToClient: 'SMS-kod mijoz raqamiga yuborildi ',
        fields: {
          otp: {
            label: 'SMS-kod',
          },
        },
        cancel: 'Bekor qilish',
        submit: 'Tasdiqlash',
      },
    },
  },
};
