export default {
  widgets: {
    searchClients: {
      phone: 'Telefon raqam',
      dateOfBirth: "Tug'ilgan sanasi",
      findClient: 'Mijozni qidirish',
      clientCard: {
        client: 'Mijoz',
        limit: 'Limit',
        hasNoLimitInfo: "Mijoz chegarasi yo'q",
      },
    },
  },
};
