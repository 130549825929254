export const ROUTE_NAMES = {
  home: 'nasiya.home',
  homeToTsd: 'nasiya.home.to-fsd',
  homeToTsdOldOnboarding: 'nasiya.home.to-fsd.old-onboarding',
  clientsList: 'nasiya.clients',
  clientsListToFsd: 'nasiya.clients.to-fsd',
  clientsShow: 'nasiya.clients.show',
  clientsShowToFsd: 'nasiya.clients.show.to-fsd',
  clientConfirmAgreement: 'nasiya.clients.confirm-agreement',
  clientsShowToVueQuery: 'nasiya.clients.show.to-vue-query',
  createApplication: 'nasiya.clients.show.create-application',
  clientsUpdateClientPassport: 'nasiya.clients.show.updatePassport',
  clientsCreate: 'nasiya.clients.create',
  clientsNew: 'nasiya.clients.new',
  clientsNewOldOnBoarding: 'nasiya.clients.new.old-onboarding',
  applicationsList: 'nasiya.applications',
  applicationsListToFsd: 'nasiya.applications.to-fsd',
  applicationsShow: 'nasiya.applications.show',
  applicationShowToFsd: 'nasiya.applications.show.to-fsd',
  applicationsCreateItems: 'nasiya.applications.create.items',
  applicationsCreateConditions: 'nasiya.applications.create.conditions',
  applicationsCreateConfirm: 'nasiya.applications.create.confirm',
  applicationsCreateAgreement: 'nasiya.applications.create.cbAgreement',
  rejectsList: 'nasiya.rejects',
  rejectsListToFsd: 'nasiya.rejects.toFsd',
  rejectsShow: 'nasiya.rejects.show',
  rejectsShowToFsd: 'nasiya.rejects.show.toFsd',
  rejectsCreate: 'nasiya.rejects.create',
  rejectsCreateToFsd: 'nasiya.rejects.create.to-fsd',
  problemsList: 'nasiya.problems',
  problemsListToFsd: 'nasiya.problems.to-fsd',
  problemsShow: 'nasiya.problems.show',
  problemsShowToFsd: 'nasiya.problems.show.toFsd',
  deliveriesList: 'nasiya.deliveries',
  deliveriesShow: 'nasiya.deliveries.show',
  deliveryShowFsd: 'nasiya.deliveries.show.to-fsd',
  deliveriesCreate: 'nasiya.deliveries.create',
  warehouseList: 'nasiya.warehouse',
  warehouseShow: 'nasiya.warehouse.show',
  notificationsList: 'nasiya.notifications',
  notificationsListFsd: 'nasiya.notifications.to-fsd',
  calculator: 'nasiya.calculator',
  calculatorToFsd: 'nasiya.calculator.to-fsd',
  calculatorPreview: 'nasiya.calculator.preview-payment',
  calculatorPreviewToFsd: 'nasiya.calculator.preview-payment.toFsd',
  excelsList: 'nasiya.excels',
  salesList: 'nasiya.sales',
  salesListToFsd: 'nasiya.sales.to-fsd',
  managerApplicationsList: 'nasiya.managerApplications',
  managerApplicationsListToFsd: 'nasiya.managerApplications.to-fsd',
  managerApplicationsShow: 'nasiya.managerApplications.show',
  invoiceList: 'nasiya.invoice',
  faqList: 'nasiya.faq',
  faqListToFsd: 'nasiya.faq.to-fsd',
  analyticsList: 'nasiya.analytics',
  qrApplicationsCreate: 'nasiya.qr-applications.create',
  requestsIndex: 'nasiya.requests',
  requestsShow: 'nasiya.requests.show',
  requestsAddItems: 'nasiya.requests.addItems',
  bonusesIndex: 'nasiya.bonuses.index',
  bonusesIndexFsd: 'nasiya.bonuses.index.to-fsd',
  offerDemandsCreate: 'nasiya.warehouse.offer-demands.create',
  offerDemandsShowPage: 'nasiya.warehouse.offer-demands.show',
  offerDemandsEditPage: 'nasiya.warehouse.offer-demands.edit',
  offerShowPage: 'nasiya.warehouse.offers.show',
  knowledgeBase: 'nasiya.knowledge-base',
  knowledgeBaseShow: 'nasiya.knowledge-base-show',
  installmentPayment: 'nasiya.installment-payment',
};
